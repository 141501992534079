import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Image from "gatsby-image"

require('./projects.css')

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    const images = {};
    for (let image in data.images.edges) {
      images[data.images.edges[image].node.relativePath] = data.images.edges[image].node
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <div class='project_list' style={{ display: `grid`, gridRowGap: `1rem`, gridColumnGap: `1rem`, gridTemplateColumns: `repeat(auto-fit, minmax(190px, 1fr))` }}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div>
                <Link to={node.fields.slug}>
                  <Image fluid={{ ...images[node.frontmatter.featured_image.relativePath].childImageSharp.fluid, aspectRatio: 1}} />
                </Link>
              </div>
            )
          })}
        </div>
        <div style={{ marginTop: rhythm(1.5) }} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter:{frontmatter:{category:{eq: "projects"}}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featured_image {publicURL, relativePath}
          }
        }
      }
    }
    images: allFile(filter: {
      absolutePath: { regex: "/content/projects/(.*)/(.*).[png|jpg]/" }
    }) {
      edges {
        node {
          relativePath
          childImageSharp  {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
